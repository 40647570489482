import React, { useState } from 'react';
import style from './image-upload.module.scss';
import ImageCropper from './componets/image-cropper/image-cropper.component';

interface ImageUploadProps {
    editingMode: boolean;
    uploadImage: (
        file: File,
        cropData?: { x: number; y: number; width: number; height: number }
    ) => void;
    imageType?: 'profile' | 'banner';
    useEdge?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
                                                     editingMode,
                                                     uploadImage,
                                                     imageType,
                                                     useEdge
                                                 }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isCropping, setIsCropping] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) return;

        const file = event.target.files[0];


        // Force reset if the same file is selected twice
        setSelectedFile(null);
        setPreviewUrl(null);

        setTimeout(() => {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));

            // If an imageType is specified (banner/profile), open the cropping modal:
            if (imageType) {
                setIsCropping(true);
            } else {
                // Otherwise, upload immediately without cropping
                uploadImage(file);
            }
        }, 50);
    };


    const handleCrop = (
        croppedFile: File,
        cropData: { x: number; y: number; width: number; height: number }
    ) => {
        // Send croppedFile + data upstream
        uploadImage(croppedFile, cropData);
        setIsCropping(false);
        // Optionally reset states if you want
        setSelectedFile(null);
        setPreviewUrl(null);
    };

    const cancelCrop = () => {
        setIsCropping(false);
        setSelectedFile(null);
        setPreviewUrl(null);

        const fileInput = document.querySelector("input[type='file']") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    };

    if (!editingMode) return null;

    const uploadStyle = useEdge ? style.uploadEdge : '';

    return (
        <>
            <label className={`${style.upload} ${uploadStyle}`}>
                <input
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    onChange={handleFileChange}
                    key={selectedFile ? selectedFile.name : "file-input"}
                />
            </label>

            {isCropping && previewUrl && imageType && (
                <ImageCropper
                    imageUrl={previewUrl}
                    imageType={imageType}
                    onCrop={handleCrop}
                    onCancel={cancelCrop}
                />
            )}
        </>
    );
};

export default ImageUpload;
